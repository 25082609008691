import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	cardContainer: {
		position: "relative",
		left: "50px",
		bottom: "60px",
		width: "calc(20vw)",
		padding: 0,
		margin: 0,
		borderStyle: "none none none solid",
		cursor: "pointer",
	},
	clientName: {
		fontSize: "16px",
		paddingLeft: "10px",
		color: "Black",
		paddingTop: "10px",
		fontWeight: "bold",
	},
	reservationDetails: {
		fontSize: "16px",
		paddingLeft: "10px",
		color: "Black",
		paddingBottom: "30px",
	},
}));

const ReservationCard = (props) => {
	const classes = useStyles();
	const { clientName, serviceName, professionalName, onClick, color } = props;

	return (
		<>
			<div
				onClick={onClick}
				className={classes.cardContainer}
				style={{ backgroundColor: color + "30", borderColor: color }}>
				<Typography
					style={{
						fontSize: "16px",
						paddingLeft: "10px",
						color: "Black",
						paddingTop: "10px",
						fontWeight: "bold",
					}}>
					{clientName}
				</Typography>
				<Typography
					style={{ fontSize: "16px", paddingLeft: "10px", color: "Black", paddingBottom: "30px" }}>
					{serviceName + " com " + professionalName}
				</Typography>
			</div>
		</>
	);
};
export default ReservationCard;

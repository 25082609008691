import { makeStyles } from "@material-ui/core/styles"; 

export default makeStyles((theme) => {
	console.log(theme);

	return {
		title: {
			color: "#393762",
			fontSize: "1.5rem", 
		},
		calendarContainer: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			[theme.breakpoints.up("md")]: {
				flexDirection: "row",
				flexWrap: "wrap",
				justifyContent: "center",
			},
		},
		calendar: {
			backgroundColor: "#eeeeee",
			marginRight: theme.spacing(0),
			width: "328px", 
		},
		reservationList: {
			flex: 1,
			overflow: "auto",
			[theme.breakpoints.up("md")]: {
				width: "50%",
			},
		},
		container: {
			margin: "auto",
			textAlign: "center",
			width: "50%",
			padding: "10px",
		},
		littlePurple: {
			color: "#393762",
			fontSize: "1.25rem", 
			lineHeight: "1.2",
			letterSpacing: "-0.5px",
			marginTop: "20px",
		},
		littleBlue: {
			color: "#3689ea",
			fontSize: "1.25rem", 
			lineHeight: "1.2", 
			letterSpacing: "-0.5px", 
			marginTop: "20px",
		},
		field: {
			width: "40px",
			fontSize: "2rem", 
			padding: "5px",
		},
		error: {
			color: "#ff3b30",
			fontSize: "0.875rem", 
		},
		icon: {
			cursor: "pointer",
			color: "#3689ea",
			transform: "scale(1.5)", 
		},
		message: {
			color: "rgba(71, 71, 71, 0.7)", 
			fontSize: "0.875rem", 
			paddingBottom: "10px",
		},
	};
});

import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import ArrowBackIosRounded from "@material-ui/icons/ArrowBackIosRounded";
import { Button, Dialog, Divider, InputAdornment, MenuItem, OutlinedInput, TextField, Typography } from "@mui/material";
import useStyles from "./styles";
import ButtonCommom from "../common/Button";
import { getAuth, updateProfile, updateEmail } from "firebase/auth";
import CommonScreen from "../common/CommonScreen";
import { Close, Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Logo } from "../common/icons/Icons";
import axios from "axios";
import MensageDialog from "../mensageDialog";
import { KeyboardDatePicker } from "@material-ui/pickers";
import clientService from "../../services/clientService";
import { useMixpanel } from "react-mixpanel-browser";

const NewClientDialog = (props) => {
	const auth = getAuth();
	const classes = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const mixpanel = useMixpanel();

	const { open, setOpen, businessId, myProfessionalId } = props;

	const [openMessage, setOpenMessage] = useState(false);
	const [message, setMessage] = useState("Erro desconhicido, tente novamente...");

	const [newClient, setNewClient] = useState({});
	const [error, setError] = useState(false);

	const states = [
		{ label: 'AC', value: 'AC' },
		{ label: 'AL', value: 'AL' },
		{ label: 'AP', value: 'AP' },
		{ label: 'AM', value: 'AM' },
		{ label: 'BA', value: 'BA' },
		{ label: 'CE', value: 'CE' },
		{ label: 'DF', value: 'DF' },
		{ label: 'ES', value: 'ES' },
		{ label: 'GO', value: 'GO' },
		{ label: 'MA', value: 'MA' },
		{ label: 'MS', value: 'MS' },
		{ label: 'MT', value: 'MT' },
		{ label: 'MG', value: 'MG' },
		{ label: 'PA', value: 'PA' },
		{ label: 'PB', value: 'PB' },
		{ label: 'PR', value: 'PR' },
		{ label: 'PE', value: 'PE' },
		{ label: 'PI', value: 'PI' },
		{ label: 'RJ', value: 'RJ' },
		{ label: 'RN', value: 'RN' },
		{ label: 'RS', value: 'RS' },
		{ label: 'RO', value: 'RO' },
		{ label: 'RR', value: 'RR' },
		{ label: 'SC', value: 'SC' },
		{ label: 'SP', value: 'SP' },
		{ label: 'SE', value: 'SE' },
		{ label: 'TO', value: 'TO' },
	  ];

	const dateFormat = (date) => {
		let formated = date;

		if (date?.length == 2) {
			formated += "/";
		}
		
		if (date?.length == 5) {
			formated += "/";
		}

		return formated;
	};

	const telephoneNumberFormat = (number) => {
		let formated = "";

		for (var i = 3; i < number?.length; i++) {
			if (i === 3) {
				formated += "(";
			}
			if (i === 5) {
				formated += ")";
			}
			if (i === 10) {
				formated += "-";
			}
			formated += number.charAt(i);
		}

		return formated;
	};

	const saveClient = () => {
		if (businessId) {
			if (auth?.currentUser) {
				let token = auth?.currentUser?.accessToken;
				let config = {
					headers: {
						Authorization: "Bearer " + token,
					},
				};
				let body = {
					...newClient,
					businessId,
				};
				clientService.postClient(body,myProfessionalId)
					.then((res) => {
						setMessage("cliente salvo com sucesso!");
						setOpenMessage(true);
						setOpen(false);
						mixpanel.track("Adicionar cliente manualmente - WB", {
							my_custom_prop: "foo",
						});
					})
					.catch((err) => {
						setMessage(err.response.data.message);
						setOpenMessage(true);
					});
			}
		}
	};

	const reset = () => {
		setNewClient({});
		openMessage(false);
	};

	return (
		<Dialog open={open || openMessage} PaperProps={{ style: { width: "500px" } }}>
			<MensageDialog open={openMessage} setOpen={setOpenMessage} message={message}></MensageDialog>
			<div style={{ display: "flex", width: "100%", backgroundColor: "#eeeeee" }}>
				<Typography
					style={{
						margin: "30px",
						color: "gray",
						width: "100%",
						fontSize: "28px",
						fontWeight: "bold",
					}}>
					Novo cliente
				</Typography>
				<div
					style={{ cursor: "pointer", width: "108px" }}
					onClick={() => {
						setOpen(false);
						reset();
					}}>
					<Close style={{ color: "gray", transform: "scale(1.5)", margin: "40px", cursor: "pointer" }}></Close>
				</div>
			</div>
			<div style={{ padding: "30px" }}>
				<TextField
					value={newClient.name}
					onChange={(e) => {
						setNewClient({ ...newClient, name: e.target.value });
					}}
					style={{ width: "100%", margin: "20px 0" }}
					label="Nome completo"
					error={error && (!newClient?.name || newClient?.name?.length == 0)}
					helperText={error && (!newClient?.name || newClient?.name?.length == 0) ? "Este campo é obrigatório" : ""}
					required
					variant="standard"></TextField>

				<TextField
					value={newClient.email}
					onChange={(e) => {
						setNewClient({ ...newClient, email: e.target.value });
					}}
					label="E-mail"
					variant="standard"
					style={{ width: "100%", margin: "20px 0" }}></TextField>

				<TextField
					value={telephoneNumberFormat(newClient?.phone)}
					onChange={(e) => {
						e.target.maxLength = 14;
						setNewClient({ ...newClient, phone: "+55" + e.target.value.replace(/\D/g, "") });
					}}
					InputProps={{
						startAdornment: newClient?.phone && <InputAdornment position="start">+55 </InputAdornment>,
					}}
					label="Telefone"
					error={error && (!newClient?.phone || newClient?.phone?.length == 0)}
					helperText={error && (!newClient?.phone || newClient?.phone?.length == 0) ? "Este campo é obrigatório" : ""}
					required
					variant="standard"
					style={{ width: "100%", margin: "20px 0" }}></TextField>

				<TextField
					value={dateFormat(newClient.birthday)}
					onChange={(e) => {
						e.target.maxLength = 10;
						setNewClient({ ...newClient, birthday: e.target.value });
					}}
					label="Data de nascimento"
					variant="standard"
					style={{ width: "100%", margin: "20px 0" }}></TextField>

				<TextField
					value={newClient.addressStreetPostalCode}
					onChange={(e) => {
						setNewClient({ ...newClient, addressStreetPostalCode: e.target.value });
					}}
					label="CEP"
					variant="standard"
					style={{ width: "100%", margin: "20px 0" }}></TextField>

				<TextField
					value={newClient.addressStreet}
					onChange={(e) => {
						setNewClient({ ...newClient, addressStreet: e.target.value });
					}}
					label="Endereço"
					variant="standard"
					style={{ width: "100%", margin: "20px 0" }}></TextField>

				<div style={{ display: "flex", margin: "20px 0" }}>
					<TextField
						value={newClient.addressNumber}
						onChange={(e) => {
							setNewClient({ ...newClient, addressNumber: e.target.value });
						}}
						label="Número"
						variant="standard"
						style={{ marginRight: "40px", width: "50%" }}></TextField>
					<TextField
						value={newClient.addressUnit}
						onChange={(e) => {
							setNewClient({ ...newClient, addressUnit: e.target.value });
						}}
						label="Complemento"
						variant="standard"
						style={{ width: "50%" }}></TextField>
				</div>

				<TextField
					value={newClient.addressCity}
					onChange={(e) => {
						setNewClient({ ...newClient, addressCity: e.target.value });
					}}
					label="Cidade"
					variant="standard"
					style={{ width: "100%", margin: "20px 0" }}></TextField>

				<TextField
					value={newClient.addressState}
					select
					onChange={(e) => {
						setNewClient({ ...newClient, addressState: e.target.value });
					}}
					label="Estado"
					variant="standard"
					style={{ width: "100%", margin: "20px 0" }}>
						{states.map((option) => (
										<MenuItem key={option.value} value={option.value}>
											{option.label}
										</MenuItem>
									))}
					</TextField>
			</div>

			<div style={{ alignItems: "center", textAlign: "center", marginBottom: "40px" }}>
				<Button
					onClick={() => {
						if (
							!newClient?.name ||
							!newClient?.phone ||
							newClient?.name?.length == 0 ||
							newClient?.phone?.length == 0
						) {
							setError(true);
						} else {
							saveClient();
						}
					}}
					style={{
						width: "400px",
						height: "60px",
						fontSize: "18px",
						padding: "20px",
						borderRadius: "45px",
						backgroundColor: false ? "gray" : "#393762",
						border: "solid 1px #fff",
						color: "#fff",
						textTransform: "none",
					}}
					variant="outlined">
					Salvar
				</Button>
			</div>
		</Dialog>
	);
};

export default NewClientDialog;

import React from "react";
import { ArrowBackIos } from "@material-ui/icons";
import CoreProgressBar from "./progressBar";
import { useNavigate } from "react-router";

const CoreAppBar = ({ progress }) => {
	const navigate = useNavigate();
	return (
		<div
			style={{
				display: "flex",
				gap: "16px",
				alignItems: "center",
				justifyContent: "space-between",
				width: "100%",
				marginTop: "16px",
			}}
			onClick={() => navigate(-1)}>
			<ArrowBackIos style={{ width: "30px", color: " #616161" }} />
			{progress && <CoreProgressBar progress={progress} />}
			<div style={{ width: "30px" }}></div>
		</div>
	);
};

export default CoreAppBar;

import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Button, Dialog, Divider, InputAdornment, OutlinedInput, TextField, Typography } from "@mui/material";

const MensageDialog = (props) => {
	const navigate = useNavigate();
	const location = useLocation();

	const { open, setOpen, message } = props;

	return (
		<Dialog open={open} PaperProps={{ style: { maxWidth: "400px" } }}>
			<div style={{ padding: "20px" }}>
				<Typography style={{ fontSize: "25px" , textAlign: "center"}}>{message}</Typography>
			</div>

			<div style={{ alignItems: "center", textAlign: "center", marginBottom: "20px" }}>
				<Button
					onClick={() => {
						setOpen(false);
					}}
					style={{
						fontSize: "16px",
						padding: "10px 30px",
						borderRadius: "45px",
						backgroundColor: false ? "gray" : "#393762",
						border: "solid 1px #fff",
						color: "#fff",
						textTransform: "none",
					}}
					variant="outlined">
					Ok
				</Button>
			</div>
		</Dialog>
	);
};

export default MensageDialog;

import React, { useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos, ExpandMore, ExpandLess } from "@material-ui/icons";
import { useMediaQuery } from "@mui/material";
import { convertMonth } from "utils";

const Calendar = (props) => {
  const backgroundColor = props?.backgroundColor;
  const selectedColor = props?.color || "#c2dbf8";
  const selectedDate = new Date(props?.date);
  const [actualDate, setActualDate] = useState(selectedDate);
  const [isExpanded, setIsExpanded] = useState(false);
  const { onChange, setDate, style, daysToNotify } = props;
  const daysOfWeek = ["Dom.", "Seg.", "Ter.", "Qua.", "Qui.", "Sex.", "Sab."];

  const isSmallScreen = useMediaQuery("(max-width: 600px)");

  const getDaysOfMonth = () => {
    let days = [[], [], [], [], [], []];
    let firstDay = new Date(actualDate.getFullYear(), actualDate.getMonth(), 1);
    let lastDay = new Date(actualDate.getFullYear(), actualDate.getMonth() + 1, 0).getDate();

    let j = 0;

    for (let i = 0; i < firstDay.getDay(); i++) {
      days[j].push(null);
      if (days[j].length === 7 && j < 5) {
        j++;
      }
    }

    for (let i = 1; i <= lastDay; i++) {
      days[j].push(i);
      if (days[j].length === 7 && j < 5) {
        j++;
      }
    }

    const remainingLength = 42 - lastDay - firstDay.getDay();

    for (let i = 0; i < remainingLength; i++) {
      days[j].push(null);
      if (days[j].length === 7 && j < 5) {
        j++;
      }
    }

    return days;
  };

  const [daysOfMonth, setDaysOfMonth] = useState([]);

  const nextMonth = () => {
    if (actualDate.getMonth() === 11) {
      setActualDate(new Date(actualDate.getFullYear() + 1, 0, 1));
    } else {
      setActualDate(new Date(actualDate.getFullYear(), actualDate.getMonth() + 1, 1));
    }
  };

  const prevMonth = () => {
    if (actualDate.getMonth() === 0) {
      setActualDate(new Date(actualDate.getFullYear() - 1, 11, 1));
    } else {
      setActualDate(new Date(actualDate.getFullYear(), actualDate.getMonth() - 1, 1));
    }
  };

  useEffect(() => {
    setDaysOfMonth(getDaysOfMonth());
  }, [actualDate]);

  const haveNotificationTo = (day) => {
    for (let i = 0; i < daysToNotify.length; i++) {
      let notifyDate = new Date(daysToNotify[i]);
      if (
        notifyDate.getDate() + 1 === day &&
        notifyDate.getMonth() === actualDate.getMonth() &&
        notifyDate.getFullYear() === actualDate.getFullYear()
      ) {
        return true;
      }
    }
    return false;
  };

  const handleDateClick = (day) => {
    if (day != null) {
      setDate(new Date(actualDate.getFullYear(), actualDate.getMonth(), day));
      onChange();
      if (isSmallScreen) {
        setIsExpanded(false);
      }
    }
  };

  return (
    <div style={style} className="calendar-container">
      <div style={style} className="container2">
        <div
          style={{
            backgroundColor: backgroundColor,
            width: "100%",
            padding: "5px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "8px",
              marginBottom: "5%",
            }}
          >
            <Typography
              style={{
                color: "#616161",
                fontSize: "15px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                fontWeight: "bold",
              }}
            >
              {`${convertMonth(actualDate.getMonth())} ${actualDate.getFullYear()}`}
            </Typography>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <Button
                onClick={prevMonth}
                style={{
                  color: selectedColor,
                  borderRadius: "100%",
                  padding: "5px",
                  marginRight: "18px",
                }}
              >
                <ArrowBackIos
                  style={{
                    color: "#616161",
                    fontSize: "18px",
                  }}
                />
              </Button>

              <Button
                onClick={nextMonth}
                style={{
                  color: selectedColor,
                  borderRadius: "100%",
                  padding: "5px",
                  marginLeft: "-48px",
                }}
              >
                <ArrowForwardIos
                  style={{
                    color: "#616161",
                    fontSize: "18px",
                  }}
                />
              </Button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              overflowX: "auto",
              whiteSpace: "nowrap",
            }}
          >
            {daysOfWeek.map((day) => (
              <Typography
                key={day}
                className="calendar-weekday"
                style={{
                  color: "lightgray",
                  textAlign: "center",
                  marginRight: "3%",
                }}
              >
                {day}
              </Typography>
            ))}
          </div>
        </div>

        <div
          style={{
            overflowX: "auto",
            width: "100%",
            marginTop: "5px",
          }}
        >
          <div
            className="calendar-grid"
            style={{
              display: 'flex',
              flexDirection: isSmallScreen ? (isExpanded ? 'column' : 'row') : 'row',
              flexWrap: isSmallScreen ? (isExpanded ? 'wrap' : 'nowrap') : 'wrap',
              overflowX: isSmallScreen && !isExpanded ? 'auto' : 'hidden',
              whiteSpace: isSmallScreen && !isExpanded ? 'nowrap' : 'normal',
            }}
          >
            {isSmallScreen && !isExpanded ? (
              daysOfMonth[0].map((day, dayIndex) => (
                <Typography
                  key={dayIndex}
                  onClick={() => handleDateClick(day)}
                  className={`calendar-day ${
                    selectedDate.getDate() === day &&
                    selectedDate.getMonth() === actualDate.getMonth() &&
                    selectedDate.getFullYear() === actualDate.getFullYear()
                      ? "selected-day"
                      : ""
                  }`}
                  style={{
                    backgroundColor:
                      selectedDate.getDate() === day &&
                      selectedDate.getMonth() === actualDate.getMonth() &&
                      selectedDate.getFullYear() === actualDate.getFullYear()
                        ? selectedColor
                        : "transparent",
                  }}
                >
                  {day}
                  {daysToNotify?.length > 0 && haveNotificationTo(day) && <div className="notification-dot" />}
                </Typography>
              ))
            ) : (
              daysOfMonth.map((week, weekIndex) => (
                <div key={weekIndex} className="calendar-week">
                  {week.map((day, dayIndex) => (
                    <Typography
                      key={dayIndex}
                      onClick={() => handleDateClick(day)}
                      className={`calendar-day ${
                        selectedDate.getDate() === day &&
                        selectedDate.getMonth() === actualDate.getMonth() &&
                        selectedDate.getFullYear() === actualDate.getFullYear()
                          ? "selected-day"
                          : ""
                      }`}
                      style={{
                        backgroundColor:
                          selectedDate.getDate() === day &&
                          selectedDate.getMonth() === actualDate.getMonth() &&
                          selectedDate.getFullYear() === actualDate.getFullYear()
                            ? selectedColor
                            : "transparent",
                      }}
                    >
                      {day}
                      {daysToNotify?.length > 0 && haveNotificationTo(day) && <div className="notification-dot" />}
                    </Typography>
                  ))}
                </div>
              ))
            )}
          </div>

          {isSmallScreen && (
            <Button
              onClick={() => setIsExpanded(!isExpanded)}
              style={{
                display: 'block',
                margin: '5px auto',
                padding: '5px',
              }}
            >
              {isExpanded ? <ExpandLess /> : <ExpandMore />}
            </Button>
          )}
        </div>
      </div>

      <style>
        {`
          .calendar-container {
            flex-direction: column;
            align-items: center;
          }

          .container2 {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 5px;
            margin-left: 5%;
          }

          .calendar-grid {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 2px;
          }

          .calendar-week {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
          }

          .calendar-day {
            width: 30px;
            height: 30px;
            color: darkgray;
            font-size: 12px;
            padding: 2px;
            text-align: center;
            border-radius: 15px;
            margin-right: 10px;
            position: relative;
          }

          .selected-day {
            color: #3689ea;
            background-color: #e0f7fa;
          }

          .notification-dot {
            background-color: #3689ea;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            position: absolute;
            top: 2px;
            right: 2px;
          }

          @media (max-width: 600px) {
            .calendar-grid {
              overflow-x: auto;
              white-space: nowrap;
            }

            .calendar-week {
              flex-direction: row;
              min-width: 100%;
            }

            .calendar-day {
              width: 40px;
              height: 40px;
              font-size: 16px;
              margin-right: 12%;
              border-radius: 20px;
            }

            .selected-day {
              color: #3689ea;
              background-color: #e0f7fa;
              height: 50px;
              font-size: 18px;
            }

            .calendar-container {
              padding: 10px;
            }

            .notification-dot {
              width: 8px;
              height: 8px;
              top: 4px;
              right: 4px;
            }

            .calendar-weekday {
              font-size: 14px;
              padding: 5px;
              min-width: 40px;
            }

            .container2 {
              margin-left: 0;
            }
          }
        `}
      </style>
    </div>
  );
};

export default Calendar;

import api from "./api";

export const getBookingTransaction = (bookingTransactionId) => {
	return api.get("booking_transaction/" + bookingTransactionId)
};

export const postBookingTransaction = (body) => {
	return api.post("booking_transaction/transactions",body)
};

export const putBookingTransaction = (body) => {
	return api.patch("booking_transaction/" + body.id,body)
};

export default {
	getBookingTransaction,postBookingTransaction,putBookingTransaction
};
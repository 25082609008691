import {initializeApp} from "firebase/app";
//import Firebase from "firebase-app";
import "firebase/auth";
import { getAuth } from "firebase/auth";

const devConfig = {
    apiKey: "AIzaSyD_3d8LKhi-9GlqtvivdfSdcZdMqbaSG2U",
    authDomain: "prit-app-dev.firebaseapp.com",
    databaseURL: "https://prit-app-dev.firebaseio.com",
    projectId: "prit-app-dev",
    storageBucket: "prit-app-dev.appspot.com",
    messagingSenderId: "1094960331739",
    appId: "1:1094960331739:web:8d1abade3c6c61bbdf05fe"
};
const config = devConfig;

export const app = initializeApp(config)

export const auth = getAuth(app);
